import React from 'react'
import Layout from 'elements/Frame/Layout'
import SEO from 'elements/Frame/SEO'
import Title from 'elements/Typography/Title'
import BackButton from 'elements/Button/BackButton'
import { Text, Image } from '@chakra-ui/core'

const NotFound = () => (
  <Layout>
    <SEO title="Not Found" />
    <Title>Not Found</Title>
    <Text>
      Oops! Looks like the page you requested doesn&apos;t exist. Or we ate it.
      Either way it&apos;s not here any more. Sad panda...
    </Text>
    <Image mt="3" size="150px" src="/images/panda.png"/>
    <BackButton />
  </Layout>
)

export default NotFound
